import React from 'react'
import media from 'styles/media'
import styled from 'styled-components'
import { graphql, PageProps } from 'gatsby'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'
import { Container } from 'components/atoms/Container'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { H1Light } from 'components/atoms/Typography'

const PrivacyContainer = styled(Container)`
  width: 100%;
  max-width: calc(1136px + 100px);

  ${media.sm.max} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const StyledH1Light = styled(H1Light)`
  padding: 72px 0px;

  ${media.sm.max} {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`

const Content = styled.div`
  max-width: 700px;

  margin: 0px auto;
  padding-top: 72px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 160%;
  font-weight: 400;

  h2 {
    text-align: center;
    font-family: 'Kumbh Sans Variable', sans-serif;
    font-size: 24px;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;

    ${media.sm.max} {
      text-align: left;
    }
  }

  ol ol {
    margin-top: 0px;
    list-style-type: lower-alpha;
  }

  li {
    margin: 0px;
  }

  a {
    color: rgba(0, 0, 0, 0.8);
  }

  ${media.md.max} {
    padding-top: 48px;
  }
`

const BreadcrumbsWraper = styled(Container)`
  ${media.sm.max} {
    margin-top: 58px;
    padding: 0;
  }
`

const PolitykaPrywatnosciPage: React.FC<
  PageProps<Queries.PolitykaPrywatnosciPageQuery>
> = ({ data }) => {
  const PAGE = data.wpPage
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <BreadcrumbsWraper margin={[97, 'auto', 0]} padding={[0, 50]}>
        <Breadcrumbs crumbs={[{ name: PAGE.title! }]} />
      </BreadcrumbsWraper>

      <PrivacyContainer margin={[0, 'auto', 97]} padding={[0, 50]}>
        <StyledH1Light
          align="center"
          as="h1"
          dangerouslySetInnerHTML={{ __html: PAGE.title! }}
        />
        <Content dangerouslySetInnerHTML={{ __html: PAGE.content! }} />
      </PrivacyContainer>
    </Layout>
  )
}

export default PolitykaPrywatnosciPage

export const query = graphql`
  query PolitykaPrywatnosciPage {
    wpPage(slug: { regex: "/polityka-prywatnosci/" }) {
      title
      content
      seo {
        ...WpSEO
      }
    }
  }
`
